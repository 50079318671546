export const COMPANY_LOADING = 'company.loading'
export const UPDATE_COMPANY = 'company.update'
export const REMOVE_COMPANY = 'company.delete'

export const companyReducer = (business: any = {}, action: any) => {
  switch (action.type) {
    case COMPANY_LOADING:
      return { isLoading: true, ...business }
    case UPDATE_COMPANY:
      return { isLoading: false, ...action.payload }
    case REMOVE_COMPANY:
      return {}
    default:
      return business
  }
}
