import { ReactElement } from "react"

type TableDataProps = {
  element: any
  keyString?: string
  classNames?: string
  customRender?: ((row: any) => string | undefined) | ((row: any) => ReactElement)
  minWidthPx?: string
}

export const TableData = ({ element, keyString, classNames, customRender, minWidthPx }: TableDataProps) => {
  const width = minWidthPx ? minWidthPx: 'min-w-[150px]'

  return (
    <td className={`pl-8 py-1 text-sm text-start ${width} ${classNames ? classNames : ''}`}>
      {keyString ? element[keyString]: customRender ? customRender(element) : <></>}
    </td>
  )
}