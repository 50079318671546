import SectionsDisplay from "./SectionsDisplay"

type FinancialSummaryProps = {
  company: any
}

const DIVISION_TYPES = [
  'financial_summary',
  'segment_revenue',
  'platform_revenue'
]

const FinancialSummary = ({ company }: FinancialSummaryProps) => {
  return (
    <SectionsDisplay company={company} divisionTypes={DIVISION_TYPES}/>
  )
}

export default FinancialSummary