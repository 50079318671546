import { formatDate } from "../utils"
import Header from "./Header"

type EventsProps = {
  company: any
}

const Events = ({ company }: EventsProps) => {
  return (
    <div className="border p-6 rounded">
      <Header className="pb-3">Upcoming Events</Header>
      {company.investor_events.length === 0 && (
        <div className="text-sm">No upcoming investor events at this time</div>
      )}
      {company.investor_events.map((event: any) => {
        const today = formatDate(event.date, true) === 'Today'
        return (
          <div key={event.id} className="flex flex-row justify-between pb-1">
            <Header size="small">{event.name}</Header>
            <div className={`text-sm ${today ? "text-ruby font-bold" : ""}`}>{formatDate(event.date, true)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Events