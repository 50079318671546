import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { fetchCookie } from "../api"
import isNil from "lodash/isNil"

const Success = () => {
  const navigate = useNavigate()
  const [params, _setParams] = useSearchParams()
  const code = params.get('code')

  useEffect(() => {
    if (isNil(code)) {
      return
    }

    fetchCookie(code).then(_data => {
      navigate('/')
    })
  }, [code])

  return (
    <div>Success</div>
  )
}

export default Success