import { jwtDecode } from "jwt-decode";
import moment from "moment";
import Cookies from 'js-cookie'
import { RIVETER_COOKIE } from "../api";

type Session = {
  account: any
  user: any
  expires_at: number
}

export const isLoggedIn = () => {
  const token = Cookies.get(RIVETER_COOKIE)

  if (token === null || token === undefined) {
    return false
  }

  return !tokenExpired(token)
}

const tokenExpired = (token: string) => {
  if (token === null || token === undefined) {
    return false
  }

  const session = jwtDecode(token) as Session

  const expired = session.expires_at <= moment().unix()

  return expired
}