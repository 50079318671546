import { useEffect, useState } from "react"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import isNil from "lodash/isNil"
import { fetchCompany, fetchSentiment } from "../../api"
import SmileyFace from "../../assets/smiley-face.svg?react"
import FrownyFace from "../../assets/frowny-face.svg?react"
import NeutralFace from "../../assets/neutral-face.svg?react"
import ExternalLink from "../../components/ExternalLink"
import Dropdown from "../../components/Dropdown"
import capitalize from "lodash/capitalize"

const sentimentColor = (sentiment: string) => {
  switch (sentiment) {
    case "positive":
      return "text-green-500"
    case "negative":
      return "text-red"
    default:
      return "text-feather"
  }
}

const SentimentIcon = ({ sentiment }: { sentiment: string }) => {
  switch (sentiment) {
    case "positive":
      return <SmileyFace className="min-w-8 min-h-8 w-8 h-8 fill-green-500 bg-green-100 rounded-full" />
    case "negative":
      return <FrownyFace className="min-w-8 min-h-8 w-8 h-8 fill-red bg-lightRuby rounded-full" />
    default:
      return <NeutralFace className="min-w-8 min-h-8 w-8 h-8 fill-feather bg-slate-100 rounded-full" />
  }
}

const SENTIMENT_ITEMS = [
  { id: "all", label: "All", href: "" },
  { id: "positive", label: "Positive", href: "?sentiment=positive" },
  { id: "negative", label: "Negative", href: "?sentiment=negative" },
  { id: "neutral", label: "Neutral", href: "?sentiment=neutral" },
]

const Sentiment = () => {
  const navigate = useNavigate()
  const { id: companyId } = useParams()
  const [overallSentiment, setOverallSentiment] = useState<any>(null)
  const [sentimentId, setSentimentId] = useState<any>(null)

  const [params, _setParams] = useSearchParams()
  const [sentiment, _setSentiment] = useState<string | null>(params.get('sentiment'))

  useEffect(() => {
    if (isNil(companyId)) {
      return
    }

    fetchCompany(navigate, companyId).then((result) => {
      if (isNil(result.sentiment)) {
        return
      }

      setSentimentId(result.sentiment.id)
    })
  }, [companyId])

  useEffect(() => {
    if (isNil(sentimentId) || isNil(companyId)) {
      return
    }

    fetchSentiment(navigate, companyId, sentimentId, params.get('sentiment'))
      .then((result) => setOverallSentiment(result))
  }, [sentimentId, sentiment, params.get('sentiment')])

  return (
    <CompanyPageContainer>
      <div className="flex flex-col p-10 w-full">
        <div className="flex flex-row justify-between pb-6">
          <div className="flex flex-col justify-between">
            <div className="font-bold text-3xl pb-6">Sentiment</div>
          </div>
          <div className="flex flex-col p-10 w-fit items-center justify-center gap-1">
            <div className="text-sm text-feather">Overall sentiment</div>
            <div className={`text-5xl font-bold ${sentimentColor(overallSentiment?.sentiment)}`}>{capitalize(overallSentiment?.sentiment)}</div>
          </div>
        </div>
        <Dropdown items={SENTIMENT_ITEMS} className="mb-6" />
        <div className="flex flex-col gap-2">
          <div className="border-b"></div>
          {overallSentiment && overallSentiment.sentiment_sources.slice(0, 10).map((sentiment_source: any) => {
            return (
              <div key={sentiment_source.id} className="flex flex-row py-4 border-b justify-between gap-4">
                <div className="line-clamp-2">{sentiment_source.content}</div>
                <div className="flex flex-col items-center justify-between gap-4">
                  <SentimentIcon sentiment={sentiment_source.sentiment} />
                  <ExternalLink classNames="w-fit self-end" url={sentiment_source.link} text={sentiment_source.sentiment_source_type}/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </CompanyPageContainer>
  )
}

export default Sentiment
