import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import { useEffect, useState } from "react";
import { fetchCompany } from "../../api";
import isNil from "lodash/isNil";
import { SkeletonLoader } from "../SkeletonLoader";
import { UPDATE_COMPANY } from "../../reducers/companyReducer";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = ({ classNames }: { classNames?: string}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams();
  const dispatch = useDispatch()
  const company = useSelector((state: any) => state.company)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isNil(id)) {
      return
    } 

    if (company?.id !== id) {
      setIsLoading(true)
      fetchCompany(navigate, id).then((result) => {
        dispatch({ type: UPDATE_COMPANY, payload: result })
        setIsLoading(false)
      })
    }
    else {
      setIsLoading(false)
    }
  }, [])

  const pages = [
    {
      name: "Overview",
      url: ``
    },
    {
      name: "Financials",
      url: `/financials`
    },
    {
      name: "Documents & Artifacts",
      url: `/documents`
    // },
    // {
    //   name: "Recent News",
    //   url: `/news`
    }
  ]

  if (!isLoading && company?.sentiment) {
    pages.push({
      name: "Sentiment",
      url: `/sentiment`
    })
  }

  let activeTab = pages.map(page => page.url).indexOf(location.pathname.slice(location.pathname.lastIndexOf("/")))
  activeTab = activeTab != -1 ? activeTab : 0
  
  return (
    <div className={`min-w-[215px] py-10 fixed ${classNames}`}>
      <>
        <Header size={"large"} className="pl-1 pb-3">{company && !isLoading ? company.name : <SkeletonLoader classNames={"h-3 w-28 my-2"}/>}</Header>
        <div>
          {
            pages.map((page: any, index: number) => {
              const innerTabStyle = activeTab === index
                ? "text-ruby font-bold border-b-ruby bg-gradient-to-t from-lightRuby to-faintOrange"
                : "border-b-slate-300"

              return (
                <div
                  key={page.name}
                  className={`border-b ${innerTabStyle} pt-2 px-1 cursor-pointer`}
                  onClick={() => {
                    navigate(`/companies/${id}${page.url}`)
                  }}
                >
                  {page.name}
                </div>
              )
            })
          }
        </div>
      </>
    </div>
  )
}

export default Sidebar