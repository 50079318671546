export const LOGIN_ACTION = 'account.login'
export const LOGOUT_ACTION = 'account.logout'

export const accountReducer = (account: any = {}, action: any) => {
  if (action.type === LOGIN_ACTION) {
    return {
      ...action.data
    }
  }

  if (action.type === LOGOUT_ACTION) {
    return {}
  }

  return account
}
