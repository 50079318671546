import { useParams } from "react-router-dom"
import ExternalLink from "../components/ExternalLink"
import Header from "../components/Header"
import PageContainer from "../components/PageContainer"

export const REPORTS = [
  {
    id: 'ab42951c-8545-4a8b-b5a4-351e8d21889d',
    name: 'Acquisition: BlackRock acquires HPS Investment Partners',
    date: '2024-12-03',
    description: 'BlackRock announced their acquisition of HPS Investment Partners this week. This is BlackRock’s third major acquisition this year, following Global Infrastructure Partners (GIP) and Preqin...',
  },
  {
    id: 'fb4eedef-3a2b-4af8-920a-23c3b380eb24',
    name: 'Product Launch: Rippling launches a PEO',
    date: '2024-11-27',
    description: 'Today, Rippling launched their PEO (Professional Employer Organization) via PR News Wire. They are the only newer player in the payroll market to offer a PEO in addition to traditional payroll services.',
  },
]

const RipplingReport = ({ report }: { report: any }) => {
  return (
    <PageContainer>
      <div className="p-10 w-full">
        <div className="font-bold text-3xl pb-6">{report.name}</div>
        <div className="pb-6">
          Today, Rippling launched their PEO (Professional Employer Organization) via&nbsp;
          <ExternalLink text="PR News Wire" url={'https://www.prnewswire.com/news-releases/rippling-unveils-new-peo-to-streamline-hr-for-remote-teams-301170238.html'} />
          . They are the only newer player in the payroll market to offer a PEO in addition to traditional payroll services.
        </div>
        <Header size={'medium'} className="pb-2">What’s a PEO?</Header>
        <div className="pb-6">
          A Professional Employer Organization (PEO) helps businesses handle HR, payroll, benefits, and compliance, reduces employer liability for certain tax and HR issues, and provides small businesses access to Large Group Health Insurance rates through a co-employment model.
        </div>
        <Header size={'xtra-large'} className="pb-2">What other major players are offering a PEO?</Header>
        <div className="pb-3">
          <ExternalLink classNames="pb-2" text="ADP" url={'https://app.riveterhq.com/companies/4f7381fe-fdd3-404c-b175-0af54fba7c55'} />
          <li>Offers both a PEO and traditional payroll, HR, and benefits via their Employer Services segment.</li>
          <li>ADP TotalSource (the PEO product) captures 20% of the PEO market share.</li>
        </div>
        <div className="pb-3">
          <ExternalLink classNames="pb-2" text="TriNet" url={'https://app.riveterhq.com/companies/ab42951c-8545-4a8b-b5a4-351e8d21889d'} />
          <li>Offers payroll, HR, and benefits only via a PEO model.</li>
          <li>TriNet captures 9% of the PEO market share.</li>
        </div>
        <div className="pb-6">
          <ExternalLink classNames="pb-2" text="Justworks" url={'https://app.riveterhq.com/companies/ab42951c-8545-4a8b-b5a4-351e8d21889d'} />
          <li>Offers payroll, HR, and benefits only via PEO. Justworks does not offer a traditional payroll, HR, and benefits product.</li>
          <li>Justworks is the most modern of the major PEO providers, founded in 2012.</li>
          <li>Justworks is a privately held company.</li>
        </div>
        <Header size={'xtra-large'} className="pb-2">Other considerations</Header>
        <div className="pb-3">
          <Header size={'medium'} className="pb-2">ACV of PEO services is typically 10x that of traditional payroll services</Header>
          <ExternalLink classNames="pb-2" text="ADP Metrics" url={'https://app.riveterhq.com/companies/4f7381fe-fdd3-404c-b175-0af54fba7c55/metrics'} />
          <li>ADP’s PEO has 17,000 customers (1.6% of ADP’s 1.1M customers)</li>
          <li>The PEO disproportionately accounts for 32% of ADP’s revenue ($6.2B)</li>
          <li>The ACV for ADP’s PEO is $132,259 vs. $11,494 for Employer Services (11.5x larger)</li>
          <li>The per employee cost for the PEO is $2,974 vs. $285 (10x larger)</li>
        </div>
        <div className="pb-6">
          <ExternalLink classNames="pb-2" text="TriNet Metrics" url={'https://app.riveterhq.com/companies/ab42951c-8545-4a8b-b5a4-351e8d21889d/metrics'} />
          <li>TriNet’s ACV is $62,345</li>
          <li>Their ARPU (cost per employee) beats ADP at $4,055</li>
        </div>
        <div className="pb-3">
          <Header size={'medium'} className="pb-2">PEOs have lower margins than traditional payroll services</Header>
          <ExternalLink classNames="pb-2" text="ADP Metrics" url={'https://app.riveterhq.com/companies/4f7381fe-fdd3-404c-b175-0af54fba7c55/metrics'} />
          <li>Of ADP’s $6.2B in PEO revenue, $2.4B is zero-margin pass-through costs. These are the costs of benefits and HR services that are paid out to other providers.</li>
          <li>ADP’s PEO EBIT margins are 15%, compared to 35% for Employer Services.</li>
        </div>
        <div className="pb-6">
          <ExternalLink classNames="pb-2" text="TriNet Metrics" url={'https://app.riveterhq.com/companies/ab42951c-8545-4a8b-b5a4-351e8d21889d/metrics'} />
          <li>TriNet has 9.5% EBIT margins</li>
        </div>
        <Header size={'xtra-large'} className="pb-2">What to expect</Header>
        <div className="pb-6">
          <li>Rippling will likely see a significant increase in revenue driven by their PEO offering</li>
          <li>This will come at the expense of their margins, due to the zero-margin pass-through costs of the PEO.</li>
          <li>Other payroll players like Gusto and Intuit’s Quickbooks Online Payroll may be more likely to add a PEO service offering.</li>
        </div>
        <Header size={'xtra-large'} className="pb-2">How Riveter can help</Header>
        <div className="pb-6">
          <li>
            <ExternalLink text="Ask Peter" url={'https://app.riveterhq.com/chat'} />&nbsp;
            to learn more about PEO and payroll service providers
          </li>
          <li>We’ll track traffic to Rippling’s
            <ExternalLink text=" PEO landing page" url={'https://www.rippling.com/peo'} />&nbsp;
            in comparison to their 
            <ExternalLink text=" normal landing page" url={'https://www.rippling.com/'} />&nbsp;
            to gauge interest.
          </li>
        </div>
      </div>
    </PageContainer>
  )
}

const BlackRockReport = ({ report }: { report: any }) => {
  return (
    <PageContainer>
      <div className="p-10 w-full">
        <div className="font-bold text-3xl pb-6">{report.name}</div>
        <div className="pb-6">
          BlackRock&nbsp;
          <ExternalLink text="announced their acquisition of HPS Investment Partners" url={'https://ir.blackrock.com/news-and-events/press-releases/press-releases-details/2024/BlackRock-to-Acquire-HPS-Investment-Partners-to-Deliver-Integrated-Solutions-Across-Public-and-Private-Markets/default.aspx'} />
          &nbsp;this week. This is BlackRock’s third major acquisition this year, following Global Infrastructure Partners (GIP) and Preqin.
        </div>
        <div className="pb-6">
          <Header size={'xtra-large'} className="pb-2">Transaction</Header>
          <li>“BlackRock to acquire HPS Investment Partners for $12B through approximately 12.1M units exchangeable 1:1 for BlackRock stock (“BLK SubCo units”)” (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
          <li>The transaction is expected to close mid-2025, subject to regulatory approvals. (
            <ExternalLink text="Press Release" url={'https://ir.blackrock.com/news-and-events/press-releases/press-releases-details/2024/BlackRock-to-Acquire-HPS-Investment-Partners-to-Deliver-Integrated-Solutions-Across-Public-and-Private-Markets/default.aspx'} />
            )
          </li>
        </div>
        <div className="pb-6">
          <Header size={'xtra-large'} className="pb-2">Strategic Rationale</Header>
          <li>In their Investor Presentation on the acquisition, BlackRock shared that they expect the private debt asset class to more than double to $4.5T by 2030. They believe this is only a small portion of the $35T TAM. (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
          <li>This doubles down on BlackRock’s September 2024 report on private debt that claimed they expected private debt AUM to reach $3.5T by the end of 2025. (
            <ExternalLink text="Private Debt: The multi-faceted growth drivers" url={'https://www.blackrock.com/institutions/en-us/literature/market-commentary/private-debt-deep-dive-multi-faceted-growth-drivers.pdf'} />
            , pg 2)
          </li>
          <li>
            The acquisition makes BlackRock a top-five credit manager with a combined ~$220B in pro-forma private credit client assets. (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
          <li>
            HPS Investment Partners brings in $148B in client assets, including $107B of fee-paying AUM. (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 5)
          </li>
          <li>
            Positioned to benefit from accelerating demand for private credit from (1) retail and institutional investors (2) Insurance companies and (3) Private equity sponsors.
          </li>
        </div>
        <div className="pb-6">
          <Header size={'xtra-large'} className="pb-2">Financial Highlights</Header>
          <li>
            “Acquired business expected to add ~$850M of 2025E base fees and $360M of post-tax 2025E FRE at nearly 50% margins, representing ~90% of distributable earnings.” (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
          <li>
            “Pro-forma private markets management fees expected to increase by ~35% to over $2.5B6” (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
          <li>
            “Estimated 16% IRR, well in excess of cost of capital”  (
            <ExternalLink text="Investor Presentation" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , pg 3)
          </li>
        </div>
        <div className="pb-6">
          <Header size={'xtra-large'} className="pb-2">Resources</Header>
          <li>
            Report:&nbsp;
            <ExternalLink text="Private Debt: The multi-faceted growth drivers" url={'https://www.blackrock.com/institutions/en-us/literature/market-commentary/private-debt-deep-dive-multi-faceted-growth-drivers.pdf'} />
            , September 6, 2024
          </li>
          <li>
            Press Release:&nbsp;
            <ExternalLink text="BlackRock to Acquire HPS Investment Partners to Deliver Integrated Solutions Across Public and Private Markets" url={'https://ir.blackrock.com/news-and-events/press-releases/press-releases-details/2024/BlackRock-to-Acquire-HPS-Investment-Partners-to-Deliver-Integrated-Solutions-Across-Public-and-Private-Markets/default.aspx'} />
            , December 3, 2024
          </li>
          <li>
            Investor Presentation:&nbsp;
            <ExternalLink text="Acquisition of HPS Investment Partners" url={'https://s24.q4cdn.com/856567660/files/doc_events/2024/BLK-HPS-Investor-Presentation.pdf'} />
            , December 3, 2024
          </li>
        </div>
      </div>
    </PageContainer>
  )
}

const Report = () => {
  const { id } = useParams()
  const report = REPORTS.find(report => report.id === id)

  if (report?.id === 'fb4eedef-3a2b-4af8-920a-23c3b380eb24') {
    return <RipplingReport report={report} />
  }

  if (report?.id === 'ab42951c-8545-4a8b-b5a4-351e8d21889d') {
    return <BlackRockReport report={report} />
  }

  return <div></div>
}

export default Report