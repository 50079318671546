import { ReactElement, useEffect } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { isLoggedIn } from "../auth/utils"
import { useDispatch, useSelector } from "react-redux"
import { LOGIN_ACTION } from "../reducers/accountReducer"
import { fetchMe } from "../api"
import isEmpty from "lodash/isEmpty"

type PrivateRouteProps = {
  children: ReactElement
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((root: any) => root.user)


  if (!isLoggedIn()) {
    return <Navigate to="/signin" replace />;
  }

  useEffect(() => {
    if (!isEmpty(user)) {
      return
    }

    fetchMe(navigate).then((data) => {
      dispatch({ type: LOGIN_ACTION, data: data })
      dispatch({ type: LOGIN_ACTION, data: data.account })
    })
  }, [location])

  return <>
    {children}
  </>
}

export default PrivateRoute