export const USER_LOGIN_ACTION = 'user.login'
export const USER_LOGOUT_ACTION = 'user.logout'

export const userReducer = (user: any = {}, action: any) => {
  if (action.type === USER_LOGIN_ACTION) {
    return {
      ...action.data
    }
  }
  if (action.type === USER_LOGOUT_ACTION) {
    return {}
  }

  return user
}