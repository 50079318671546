import { useEffect, useState } from "react"
import { Input } from "../components/Input"
import PageContainer from "../components/PageContainer"
import { createChat, createChatMessage, fetchChat } from "../api"
import { useNavigate } from "react-router-dom"
import { useInterval } from "../utils"
import FormattedText from "../components/FormattedText"
import { ThreeDots } from "react-loader-spinner"
import RLogo from "./../assets/r-logo.svg?react"

const Chat = () => {
  const navigate = useNavigate()
  const [chatId, setChatId] = useState<string | null>(null)
  const [messages, setMessages] = useState<any[]>([])
  const [_submittingMessage, setSubmittingMessage] = useState(false)
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  useEffect(() => {
    if (chatId) {
      return
    }

    createChat(navigate).then((res) => {
      setChatId(res.id)
    })
  }, [])

  useInterval(() => {
    if (!chatId) {
      return
    }

    fetchChat(navigate, chatId).then((res: any) => {
      setMessages(res.messages.reverse())

      if (res.status === "waiting") {
        setWaitingForResponse(false)
      }
    })
  }, 2000)

  const handleMessageSubmit = (e: any) => {
    const message = e.target.value
    if (!chatId) {
      return
    }

    setSubmittingMessage(true)
    createChatMessage(navigate, chatId, message).then(() => {
      setMessages([{
        role: "user",
        content: message
      }, ...messages,])

      setSubmittingMessage(false)
      setWaitingForResponse(true)
    })

    e.target.value = ""
  }

  console.log(chatId)

  return (
    <PageContainer>
      <div className="min-h-lvh">
        <div className={"flex flex-row items-center gap-1 font-bold text-3xl px-10 pt-10 pb-6"}>Ask Peter</div>
        <div className="flex flex-col h-[calc(100vh-200px)]">
          <div className="flex flex-1 flex-col-reverse overflow-y-auto px-10 gap-4 mb-4">
            {waitingForResponse && (
              <div className="max-w-[70%] w-fit rounded-lg px-4 py-2 bg-gray-100">
                <ThreeDots
                  visible={true}
                  height="30"
                  width="30"
                  color="#92111E"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {messages?.map((message, index) => (
              <div key={index} className={`flex ${message.role === "user" ? 'justify-end' : 'justify-start'}`}>
                <div className={`max-w-[70%] rounded-lg px-4 py-2 ${
                  message.role === "user" ? 'bg-ruby text-white' : 'bg-gray-100'
                }`}>
                  <FormattedText text={message.content} />
                </div>
              </div>
            ))}
            <div className="flex absolute justify-center items-center self-center h-[calc(100%-200px)] w-full left-0 right-0 -z-10">
              <RLogo className="h-24" color="#FBDADE"/>
            </div>
          </div>
          <div className="px-10 pb-6">
            <Input 
              className="w-full"
              placeholder="What can I look into for you?"
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  handleMessageSubmit(e)
                }
              }}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default Chat
