import { ReactElement } from "react"
import Sidebar from "./Sidebar"

type PageContainerProps = {
  children?: ReactElement
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className="min-w-fit h-dvh">
      {/* <Header /> */}
      <div className={'flex'}>
        <Sidebar/>
        <div className="pl-5 pr-10 bg-opacity-80 min-h-screen w-full pt-10">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageContainer