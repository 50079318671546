import { useNavigate } from "react-router-dom"
import { formatDate } from "../utils"
import PageContainer from "../components/PageContainer"
import { Table } from "../components/Table"
import { REPORTS } from "./Report"

const REPORT_CONFIG = [
  {
    header: 'Name',
    key: 'name'
  },
  {
    header: 'Date',
    customRender: (row: any) => {
      console.log(row)
      return (
        <div className="my-4">{formatDate(row.date)}</div>
      )
    }
  }
]

const Reports = () => {
  const navigate = useNavigate()

  return (
    <PageContainer>
      <div className="p-10 w-full">
        <div className="font-bold text-3xl pb-6">Reports</div>
        <Table
          columnConfigs={REPORT_CONFIG}
          rows={REPORTS}
          onRowClick={(row) => { navigate(`/reports/${row.id}`) }}
        />
      </div>
    </PageContainer>
  )
}

export default Reports