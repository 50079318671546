import { Tooltip } from "react-tooltip"
import { ColumnTable } from "../../ColumnTable"
import FormattedText from "../../FormattedText"
import Header from "../../Header"
import SectionsDisplay from "./SectionsDisplay"
import { MathJax } from "better-react-mathjax"
import { startCase } from "lodash"

const DIVISIONS = ['company', 'segment', 'subsegment', 'product']

type MetricsOverviewProps = {
  company: any
}

const CAC_ROW_CONFIGS = [
  {
    title: 'Sales and Marketing Expense',
    key: 'metric',
    titleClassNames: 'justify-start text-start'
  },
  {
    title: '(÷) Number of New Customers Acquired',
    key: 'metric',
    titleClassNames: 'justify-start text-start'
  },
  {
    title: 'Customer Acquisition Cost (CAC) (dollars)',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple font-bold'
  },
  {
    title: '',
    key: '',
    titleClassNames: 'h-6',
    empty: true
  },
  {
    title: 'New revenue',
    key: 'metric',
    titleClassNames: 'justify-start text-start'
  },
  {
    title: '(÷) Number of New Customers Acquired',
    key: 'metric',
    titleClassNames: 'justify-start text-start'
  },
  {
    title: 'Average New ARR (dollars)',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple font-bold'
  },
  {
    title: '',
    key: '',
    titleClassNames: 'h-6',
    empty: true
  },
  {
    title: 'Gross Margin',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple font-bold'
  },
  {
    title: '',
    key: '',
    titleClassNames: 'h-6',
    empty: true
  },
  {
    title: 'CAC Payback Period (yrs)',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple font-bold',
    customRender: (row: any) => {
      const cac = row.metric === '2.88' ? '$10,262' : row.metric === '4.14' ? '$14,844' : '$8,099'
      const avg_arr = row.metric === '2.88' ? '$3,935' : row.metric === '4.14' ? '$3,986' : '$2,619'
      const gross_margin = row.metric === '2.88' ? '90.5%' : row.metric === '4.14' ? '90.0%' : '89.3%'
      const gross_margin_value = row.metric === '2.88' ? 0.905 : row.metric === '4.14' ? 0.90 : 0.893

      const segment = row.metric === '2.88' ? 'consumer' : row.metric === '4.14' ? 'commercial' : 'international'

      const metrics = [{
        label: 'Customer Acquisition Cost (CAC) (dollars)',
        value: cac,
        segment: segment
      },
      {
        label: 'Average New ARR (dollars)',
        value: avg_arr,
        segment: segment
      },
      {
        label: 'Gross Margin',
        value: gross_margin,
        segment: segment
      }]

      return (
        <>
          <div id={segment}>
            {row.metric}
          </div>
          <Tooltip
            anchorSelect={`#${segment}`}
            variant="light"
            className="border !opacity-100"
            classNameArrow="border-b border-r"
            clickable
            openOnClick
            closeEvents={{ click: true }}
          >
            <div className="flex flex-col justify-between w-[400px] my-3">
              <div className="flex flex-col justify-start text-start">
                {metrics.map((metric: any) => {
                  return (
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between text-base">
                          <div className="font-bold">{metric.label}</div>
                          <div className="font-medium">{metric.value}</div>
                      </div>
                      <div className="text-feather text-xs pb-1">{startCase(metric.segment)}</div>

                    </div>
                  )
                })}
              </div>
              <MathJax className="pt-2">{`\\(\\LARGE{\\frac{${cac}}{${avg_arr} \\times ${gross_margin_value}}}\\)`}</MathJax>
            </div>
          </Tooltip>
        </>
      )
    }
  }
]

const CAC_DATA = [
  [
    {
      'metric': '$15,393'
    },
    {
      'metric': '$7,422'
    },
    {
      'metric': '$5,669'
    }
  ],
  [
    {
      'metric': '1,500,000'
    },
    {
      'metric': '500,000'
    },
    {
      'metric': '700,000'
    }
  ],
  [
    {
      'metric': '$10,262'
    },
    {
      'metric': '$14,844'
    },
    {
      'metric': '$8,099'
    }
  ],
  [],
  [
    {
      'metric': '$5,903'
    },
    {
      'metric': '$1,993'
    },
    {
      'metric': '$1,833'
    }
  ],
  [
    {
      'metric': '1,500,000'
    },
    {
      'metric': '500,000'
    },
    {
      'metric': '700,000'
    }
  ],
  [
    {
      'metric': '$3,935'
    },
    {
      'metric': '$3,986'
    },
    {
      'metric': '$2,619'
    }
  ],
  [],
  [
    {
      'metric': '90.5%'
    },
    {
      'metric': '90.0%'
    },
    {
      'metric': '89.3%'
    }
  ],
  [],
  [
    {
      'metric': '2.88'
    },
    {
      'metric': '4.14'
    },
    {
      'metric': '3.46'
    }
  ]
]

const NETWORK_ROW_CONFIGS = [
  {
    title: 'Total Network Volumes',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
  },
  {
    title: 'US Consumer',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: 'Commercial Services',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: 'US SME*',
    key: 'metric',
    titleClassNames: 'justify-start text-start',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: 'US Large & Global Corp',
    key: 'metric',
    titleClassNames: 'justify-start text-start',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: 'International Card Services',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: "Int'l Consumer",
    key: 'metric',
    titleClassNames: 'justify-start text-start',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: "Int'l SME & Large Corp",
    key: 'metric',
    titleClassNames: 'justify-start text-start',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
  {
    title: "Processed Volumes",
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    customRender: (row: any) => {
      return (
        <div className={row.derived ? 'text-brightPurple' : ''}>
          {row.metric}
        </div>
      )
    }
  },
]

const NETWORK_DATA = [
  [
    {
      metric: '$434'
    }
  ],
  [
    {
      metric: '$161',
      derived: true
    },
    {
      metric: '37%'
    }
  ],
  [
    {
      metric: '$130.20',
      derived: true
    },
    {
      metric: '30%'
    }
  ],
  [
    {
      metric: '$108.50',
      derived: true
    },
    {
      metric: '25%'
    }
  ],
  [
    {
      metric: '$21.70',
      derived: true
    },
    {
      metric: '5%'
    }
  ],
  [
    {
      metric: '$86.80',
      derived: true
    },
    {
      metric: '20%'
    }
  ],
  [
    {
      metric: '$56.42',
      derived: true
    },
    {
      metric: '13%'
    }
  ],
  [
    {
      metric: '$30.38',
      derived: true
    },
    {
      metric: '7%'
    }
  ],
  [
    {
      metric: '$56',
      derived: true
    },
    {
      metric: '13%'
    }
  ]
]

const GTM_ROW_CONFIGS = [
  {
    title: 'Total',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple',
  },
  {
    title: 'U.S. Consumer Services (USCS)',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple',
  },
  {
    title: 'Commercial Services (CS)',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple',
  },
  {
    title: 'International Card Services (ICS)',
    key: 'metric',
    titleClassNames: 'justify-start text-start font-bold',
    metricClassNames: 'text-brightPurple',
  },
]

const GTM_DATA = [
  [
    {
      metric: '42.3%'
    }
  ],[
    {
      metric: '50.0%'
    }
  ],[
    {
      metric: '45.7%'
    }
  ],[
    {
      metric: '49.1%'
    }
  ]
]

const MetricsOverview = ({ company }: MetricsOverviewProps) => {
  const displayAmex = company.id === 'dc95d38d-da13-4e03-977d-0656832aaa56'

  return (
    <>
      <SectionsDisplay company={company} divisionTypes={DIVISIONS} />
      {displayAmex && (
        <>
          <div>
            <Header className="pb-2 pt-6" size={'xtra-large'}>CAC & CAC Payback Period (FY23)</Header>
            <div className="pb-2 text-sm">
              The figures below are estimations of CAC and CAC payback per segment based on the information available.
            </div>
            <ColumnTable
              rowConfigs={CAC_ROW_CONFIGS}
              data={CAC_DATA}
              columnHeaders={['U.S. Consumer Services (USCS)','Commercial Services (CS)','International Card Services (ICS)']}
              clickDisabled={(row) => row.status !== 'completed'}
              tableClassNames="rounded-md"
            />
          </div>
          <div>
            <Header className="pb-2 pt-6" size={'xtra-large'}>Network Volume by Segment (Q4 FY23)</Header>
            <div className="pb-2 text-sm">The breakdown of network volume by segment and sub-segment.</div>
              <ColumnTable
                rowConfigs={NETWORK_ROW_CONFIGS}
                data={NETWORK_DATA}
                columnHeaders={['Network Volume','% of Total']}
                clickDisabled={(row) => row.status !== 'completed'}
                tableClassNames="rounded-md"
              />
              <FormattedText className="text-sm" text={" * SME refers to small and mid-sized businesses with less than $300MM in annual revenues."}></FormattedText>
          </div>
          <div>
            <Header className="pb-2 pt-6" size={'medium'}>Sales & Marketing Expense as a % of Revenue</Header>
              <ColumnTable
                rowConfigs={GTM_ROW_CONFIGS}
                data={GTM_DATA}
                columnHeaders={["FY'23"]}
                clickDisabled={(row) => row.status !== 'completed'}
                tableClassNames="rounded-md"
              />
              <FormattedText className="text-sm" text={" * Generally, Sales & Marketing expenses in the financial services industry can range from 20% to 40% of revenues. Amex's ratios prove to be strong indicators of its ongoing investment to attract and retain customers"}></FormattedText>
          </div>
        </>
      )}
    </>
  )
}

export default MetricsOverview