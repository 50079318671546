import moment from "moment"
import { useEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"
import { clsx, ClassValue } from 'clsx'

const DATE_FORMAT = 'MM/DD/YYYY'

const PUBLISHED_DATE_FORMAT = 'MMM Do yyyy'

export const formatUrl = (url?: string | null) => {
  if (!url) {
    return url
  }

  const httpRegex = /^https*:\/\/.+/

  const hasScheme = url.match(httpRegex)

  if (hasScheme) {
    return url
  }

  return `https://${url}`
}

export const formatTime = (timeString?: string) => {
  if (!timeString) {
    return ''
  }

  const time = moment(timeString)

  if (time > moment(new Date()).subtract(24, "hours")) {
    return time.fromNow()
  }

  return time.format(DATE_FORMAT)
}

export const formatPercentage = (percentage: number) => {
  return `${Math.floor(percentage * 100)}%` 
}

export const formatDate = (dateString?: string, highlightToday: boolean = false) => {
  if (!dateString) {
    return
  }

  if (highlightToday && moment(dateString) < moment(new Date())) {
    return "Today"
  }

  return moment(dateString).format(PUBLISHED_DATE_FORMAT)
}

export const useInterval = (callback: any, delay: number) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      if (savedCallback === undefined) {
        return
      }
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}