import { MathJax } from "better-react-mathjax"

const isCalculation = (section: string) => {
  return section.match(/^\\\[.*\\\]/) || section.match(/^\\\(.*\\\)/)
}

const FormattedText = ({ className, text }: { className?: string, text?: string}) => {
  if (!text) {
    return <></>
  }

  const squishLatekText = text.replace(/\\\[\n/, '\\\[').replace(/\n\\\]/, '\\\]')
  const lines = squishLatekText.split('\n')

  return (
    <div className={className}>
      <MathJax>
        {lines.map((line, index) => {
          let sanitizedLine = line.replaceAll("#", "").replaceAll('\\"', '"')
          const matchedBoldRegex = sanitizedLine.matchAll(/\*\*([^\*]*)\*\*/g)
          const bulletPoint = line.match(/^[\*-][^\*]/)
          sanitizedLine = sanitizedLine.replace(/^[\*-][^\*]/, "")

          const boldLineSegment = new Set(Array.from(matchedBoldRegex).map((matchedData: any) => {
            return matchedData[1]
          }))
          const splitLine = sanitizedLine.split("**")

          const displayLine = () => {
            return (
              splitLine.map((snippet: any) => {
                if (boldLineSegment.has(snippet)) {
                  return <b className="font-bold">{snippet}</b>
                }

                if (isCalculation(snippet)) {
                  return <MathJax>{snippet}</MathJax>
                }

                return snippet.toString()
              }))
            }

          return (
            <div key={index}>
              {`${bulletPoint ? '•' : '' } `}
              {displayLine()}
              {index !== lines.length - 1 && (<><br /></>)}
            </div>
          )
        })}
      </MathJax>
    </div>
  )
}

export default FormattedText