import { ReactElement, useState } from "react"
import { cn } from "../utils"
import { useNavigate } from "react-router-dom"
import ExtendedLogo from "./../assets/extended-logo.svg?react" 
// import CompanyIcon from "./../assets/company.svg?react" 
import HomeIcon from "./../assets/home.svg?react"
import NewsIcon from "./../assets/news.svg?react"

import SettingsIcon from "./../assets/settings.svg?react"
import ThreeSparkles from "./../assets/three-sparkles.svg?react"
import { useSelector } from "react-redux"

const SECTIONS = [
  {
    title: 'Overview',
    url: '/',
    Icon: HomeIcon
  },
  // {
  //   title: 'Companies',
  //   url: '/companies',
  //   Icon: CompanyIcon
  // },
  // {
  //   title: 'News',
  //   url: '/news',
  //   Icon: NewsIcon
  // },
  {
    title: 'Ask Peter',
    url: '/chat',
    Icon: ThreeSparkles
  },
  {
    title: 'Settings',
    url: '/settings',
    Icon: SettingsIcon
  }
]


const Title = ({ className, children, onClick }: { className?: string, children: ReactElement | string, onClick?: () => void}) => {
  return (
    <div className={cn(className, "text-xl font-semibold px-6 py-2 cursor-pointer")} onClick={onClick}>
      {children}
    </div>
  )
}

const Sidebar = () => {
  const navigate = useNavigate()
  const account = useSelector((state: any) => state.account)

  let sections = SECTIONS
  if (account.id === '702a515e-4ff6-4a1a-b1cb-1c3bab1e54b2') {
    sections = [...sections, {
      title: 'Reports',
      url: '/reports',
      Icon: NewsIcon
    }]
  }

  const currentRoute = location.pathname.split("/")[1]
  let activeTab = SECTIONS.map(section => section.url).indexOf(`/${currentRoute}`)
  activeTab = activeTab != -1 ? activeTab : 0
  const [activeIndex, setActiveIndex] = useState(activeTab)

  return (
    <div className="static border-r min-h-lvh min-w-[250px] bg-faintOrange">
      <div className="fixed">
        <div className="flex flex-row justify-between items-center w-full p-6">
          <button className="cursor-pointer" onClick={() => { navigate('/') }}>
            <ExtendedLogo className="h-8 w-full"/>
          </button>
        </div>
        <div className="flex flex-col justify-between">

          <div className="flex flex-col gap-3">
            {SECTIONS.map((section, index) => {
              return (
                <Title
                  key={section.title}
                  className={activeIndex === index ? "text-ruby bg-white" : ""}
                  onClick={() => {
                    setActiveIndex(index)
                    navigate(section.url)
                  }}
                >
                  <div className="flex flex-row gap-4">
                    {section.Icon && <section.Icon className="h-6" color={activeIndex === index ? "#92111E" : 'slate-900'}/> }
                    {section.title}
                  </div>
                </Title>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar