import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchCompany } from "../../api"
import ProductUpdates from "../../components/ProductUpdates"
import Events from "../../components/Events"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import isNil from "lodash/isNil"
import SectionsDisplay from "../../components/Company/Financials/SectionsDisplay"
import { SkeletonLoader } from "../../components/SkeletonLoader"
import { useDispatch, useSelector } from "react-redux"
import { UPDATE_COMPANY } from "../../reducers/companyReducer"

const Company = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const dispatch = useDispatch()
  const company = useSelector((state: any) => state.company)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isNil(id)) {
      return
    }

    if (company?.id !== id) {
      setIsLoading(true)
      fetchCompany(navigate, id).then((result) => {
        dispatch({ type: UPDATE_COMPANY, payload: result })
        setIsLoading(false)
      })
    }
    else {
      setIsLoading(false)
    }
  }, [id, company?.id])

  const METRIC_SECTIONS = ['company']

  return (
    <CompanyPageContainer>
      <div className="flex flex-col pb-20 w-full h-full">
        <div className="p-10 w-full h-full">
          {isLoading && <SkeletonLoader classNames={"w-full h-5"}/>}
          {!isLoading && !isNil(company?.id) && (
            <>
              <div className="font-bold text-3xl pb-6">Overview - {company.name}</div>
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-3 grid-flow-rows gap-6 w-full">
                  <ProductUpdates className="col-span-2" company={company} />
                  <Events company={company}/>
                </div>
                <SectionsDisplay company={company} divisionTypes={METRIC_SECTIONS} />
              </div>
            </>
          )}
        </div>
      </div>
    </CompanyPageContainer>
  )
}

export default Company