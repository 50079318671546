import PageContainer from "../components/PageContainer"
import Button from "../components/Button"
import Cookies from "js-cookie"
import { logout, RIVETER_COOKIE } from "../api"
import { useDispatch } from "react-redux"
import { LOGOUT_ACTION } from "../reducers/accountReducer"

const Settings = () => {
  const dispatch = useDispatch()

  const signOut = () => {
    logout().then((data) => {
      dispatch({ type: LOGOUT_ACTION })
      Cookies.remove(RIVETER_COOKIE)
      Cookies.remove(RIVETER_COOKIE, { domain: '.riveterhq.com' })

      window.location.href = data.data.redirect_url
    })
  }

  return (
    <PageContainer>
      <div className="flex flex-col p-10 w-full gap-6">
        <div className={"font-bold text-3xl"}>Account Settings</div>
        <div>
          <Button onClick={signOut}>Sign out</Button>
        </div>
      </div>
    </PageContainer>
  )
}

export default Settings