import { ReactElement } from "react"
import PageContainer from "../PageContainer"
import Sidebar from "./Sidebar"

const CompanyPageContainer = ({ children }: {children: ReactElement}) => {
  return (
    <PageContainer>
      <div className="grid grid-cols-5">
        <div className="col-span-4">
          {children}
        </div>
        <div className="col-span-1">
          <Sidebar classNames="pl-5"/>
        </div>
      </div>
    </PageContainer>
  )
}

export default CompanyPageContainer