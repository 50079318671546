import { cn, formatDate } from "../utils"
import ExternalLink from "./ExternalLink"
import { Table } from "./Table"


const NEWS_COLUMN_CONFIG = [
  {
    header: 'Title',
    customRender: (row: any) => {
      return (
        <ExternalLink url={row.url}>
          {row.title}
        </ExternalLink>
      )
    }
  },
  {  
    header: 'Published at',
    customRender: (row: any) => {
      return (formatDate(row.publication_date))
    }
  },
  {  
    header: 'Author',
    key: 'author'
  }
]

const News = ({ news, className }: { news: any[], className?: string }) => {
  return (
    <div className={cn(className)}>
      <div className={"font-bold text-3xl pb-6"}>News</div>
      <Table
        rows={news}
        columnConfigs={NEWS_COLUMN_CONFIG}
      />
    </div>
  )
}

export default News