import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ChevronDownIcon,
} from '@heroicons/react/16/solid'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cn } from '../utils'
import capitalize from 'lodash/capitalize'

type ComboboxItem = { id: string, label: string, href: string }

type ComboboxProps = { 
  items: ComboboxItem[]
  className?: string
}

const Dropdown = ({ items, className }: ComboboxProps) => {
  const navigate = useNavigate()
  const [params, _setParams] = useSearchParams()

  const displayText = capitalize(params.get("sentiment") || "") || "All"

  return (
    <Menu>
      <MenuButton className={cn("flex w-32 justify-between items-center gap-1 rounded-md py-1.5 px-3",
        "text-sm/6 shadow-inner shadow-black/10 focus:outline-none data-[hover]:bg-gray-100",
        "data-[open]:bg-white data-[focus]:outline-1 data-[focus]:outline-black",
        className
      )}>
        {displayText}
        <ChevronDownIcon className="size-5 fill-feather" />
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className={cn("flex flex-col w-32 origin-top-right bg-white rounded border shadow-inner",
          "shadow-black/10 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1",
          "focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0")}
      >
        <div className="flex flex-col">
        {items.map((item) => {
          return (
            <MenuItem key={item.id}>
              <button
                className="group justify-start text-start rounded py-1.5 px-3 bg-white hover:bg-gray-100"
                onClick={() => navigate(item.href)}
              >
                {item.label}
              </button>
            </MenuItem>
            )
          })}
        </div>
      </MenuItems>
    </Menu>
  )
}

export default Dropdown