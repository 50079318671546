import { useEffect, useState } from "react"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import { isNil } from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import { fetchNewsArticles } from "../../api"
import NewsTable from "../../components/NewsTable"

const CompanyNews = () => {
  const navigate = useNavigate()
  const [news, setNews] = useState<any | null>()
  const { id } = useParams()

  useEffect(() => {
    if (isNil(id)) {
      return
    }

    fetchNewsArticles(navigate, id).then(data => setNews(data))
  }, [id])

  return (
    <CompanyPageContainer>
      <NewsTable className="p-10 w-full" news={news} />
    </CompanyPageContainer>)
}

export default CompanyNews