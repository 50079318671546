import snakeCase from "lodash/snakeCase"
import { Tooltip } from "react-tooltip"
import ExternalLink from "./ExternalLink"
import { formatDocName } from "../pages/Company/Documents"

export const sourceTooltipAnchor = (
  division_name: string,
  row: any,
  rowIndex: number,
  columnIndex: number
) => {
  return `anchor-${snakeCase(division_name)}-${row.year}-${row.quarter}-${rowIndex}-${columnIndex}`
}

const MetricSourceTooltip = ({ id, row, anchorSelector }: { id: string, row: any, anchorSelector: string}) => {
  return (
    <Tooltip
      anchorSelect={`#${anchorSelector}`}
      variant="light"
      className="border !opacity-100"
      classNameArrow="border-r border-b"
      clickable
      openOnClick
      closeEvents={{ click: true }}
    >
      <div className="flex flex-row justify-between w-[200px] my-3 font-semibold text-base">
        <ExternalLink url={`${import.meta.env.VITE_APP_URL}/companies/${id}/documents`}>{formatDocName(row.source.document)}</ExternalLink>
        {row?.source?.page_number && `pg ${row?.source?.page_number}`}
      </div>
    </Tooltip>
  )
}

export default MetricSourceTooltip