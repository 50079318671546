import { useSelector } from "react-redux"
import NewsTable from "../components/NewsTable"
import PageContainer from "../components/PageContainer"
import { useEffect, useState } from "react"
import { fetchNewsArticles } from "../api"
import { useNavigate } from "react-router-dom"
import isNil from "lodash/isNil"

const News = () => {
  const navigate = useNavigate()
  const account = useSelector((root: any) => root.account)
  const [news, setNews] = useState<any[]>([])

  useEffect(() => {
    if (isNil(account.id)) {
      return
    }

    fetchNewsArticles(navigate).then(data => setNews(data))
  }, [account.id])

  console.log(news)

  return (
    <PageContainer>
      <NewsTable className="p-10 w-full" news={news}/>
    </PageContainer>
  )
}

export default News