import { useEffect } from "react"
import { fetchCompany } from "../../api"
import { useNavigate, useParams } from "react-router-dom"
import isNil from "lodash/isNil"
import { Tabs } from "../../components/Tabs"
import CompanyPageContainer from "../../components/Company/CompanyPageContainer"
import FinancialsOverview from "../../components/Company/Financials/Overview"
import FinancialSummary from "../../components/Company/Financials/FinancialSummary"
import { useDispatch, useSelector } from "react-redux"
import { COMPANY_LOADING, UPDATE_COMPANY } from "../../reducers/companyReducer"

export const YEARS = ['2021', '2022', '2023', '2024']
// export const YEARS = [ '2024', '2023', '2022', '2021']

export const lastXYears = (latestYear: string, lookback: number) => {
  return new Array(lookback).fill(0).map((_el, index) => {
    return String(Number(latestYear) - index)
  })
}

const Financials = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const company = useSelector((state: any) => state.company)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isNil(id)) {
      return
    } 

    if (company?.id !== id) {
      dispatch({ type: COMPANY_LOADING })
      fetchCompany(navigate, id).then((result) => {
        dispatch({ type: UPDATE_COMPANY, payload: result })
      })
    }  }, [])

  const tabs = [{
      title: 'Financial Overview',
      tabContent: (<FinancialsOverview company={company} />)
    },{
      title: id === 'f97ad416-56dd-4604-81bf-84ff45f828f8' ? 'Income Statement' : 'Financial Summary',
      tabContent: <FinancialSummary company={company}></FinancialSummary>
    }
  ]

  return (
    <CompanyPageContainer>
      <div className="p-10 w-full">
          <div className="font-bold text-3xl pb-6">Financials</div>
          {!isNil(company?.id) && <Tabs tabs={tabs} />}
      </div>
    </CompanyPageContainer>
  )
}

export default Financials