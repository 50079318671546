import { useNavigate } from "react-router-dom"
import PageContainer from "../components/PageContainer"
import { Table } from "../components/Table"
// import Pill from "../components/Pill"
import Header from "../components/Header"
import BoxList from "../components/BoxList"
import { useEffect, useState } from "react"
import { fetchCompanyUpdates, fetchOverview } from "../api"
import { formatDate } from "../utils"
import { useSelector } from "react-redux"
import startCase from "lodash/startCase"
import ExternalLink from "../components/ExternalLink"
import { GlobeAltIcon } from "@heroicons/react/24/outline"
import LinkedInIcon from "../assets/linkedin-logo.svg?react"
import XIcon from "../assets/x-logo.svg?react"
import { REPORTS } from "./Report"

const columnConfigs = [{
  header: 'Company',
  customRender: (row: any) => {
    return (
      <div className="flex flex-row gap-2 items-center p-2 text-base">
        <img src={row.company.logo_url} className="rounded-full h-10 "/>
        {row.company.name}
      </div>
    )
  } 
},
{
  header: 'Stage',
  classNames: 'p-2 text-base',
  customRender: (row: any) => {
    return startCase(row.company.funding_stage)
  }
},
{
  header: 'Links',
  customRender: (row: any) => {
    return (
      <div className="flex flex-row gap-2">
        {row.company.website_url && <ExternalLink url={row.company.website_url}>
          <GlobeAltIcon className="h-5 stroke-ruby" />
        </ExternalLink>}
        {row.company.linkedin_url &&<ExternalLink url={row.company.linkedin_url}>
          <LinkedInIcon className="h-5 stroke-ruby" ></LinkedInIcon>
        </ExternalLink>}
        {row.company.x_url &&<ExternalLink url={row.company.x_url}>
          <XIcon className="h-5 stroke-ruby" ></XIcon>
        </ExternalLink>}
      </div>
    )
  }
// },
// {
//   header: 'Updates',
//   classNames: 'p-2',
//   customRender: (row: any) => {
//     if (!row.updates || row.updates.length === 0) {
//       return (<></>)
//     }

//     return (
//       <div className="flex justify-start items-start">
//         <Pill text="New" textColor="text-purple" color="bg-faintPurple" border="border border-purple"/>
//       </div>
//     )
//   }
}]

const upcomingWebinarConfig = [{
  header: 'Title',
  key: 'name',
  classNames: 'line-clamp-2'
},
{
  header: 'Date',
  customRender: (row: any) => {
    return formatDate(row.date)
  }
}]

const Report = ({ alert }: { alert: any }) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-1 cursor-pointer py-6" onClick={() => navigate(`/reports/${alert.id}`)}>
      <div className="flex flex-row items-center gap-2">
        <div className='bg-purple/75 rounded w-1 h-1 shadow-[0_0_3px_3px_rgba(68,17,146,0.5)]'></div>
        <Header size={'large'}>{alert.name}</Header>
      </div>
      <div className="text-sm text-feather">{formatDate(alert.date)}</div>
      <div className="line-clamp-2">{alert.description}</div>
    </div>
  )
}

const Home = () => {
  const navigate = useNavigate()
  const [upcomingInvestorEvents, setUpcomingInvestorEvents] = useState<any[]>([])
  const [webinarsConferences, setWebinarsConferences] = useState<any[]>([])
  const [investorNews, setInvestorNews] = useState<any[]>([])
  const [competitors, setCompetitors] = useState<any[]>([])
  const [newCompetitors, setNewCompetitors] = useState<any[]>([])
  const [indirectCompetitors, setIndirectCompetitors] = useState<any[]>([])
  const [companyUpdates, setCompanyUpdates] = useState<any[]>()
  const currentAccount = useSelector((root: any) => root.account)
  const currentUser = useSelector((root: any) => root.user)
  const [reports, setReports] = useState<any[]>([])
  const isAdmin = currentAccount.id === '702a515e-4ff6-4a1a-b1cb-1c3bab1e54b2' || currentAccount.id === '34ed70b4-a40b-439f-b98c-aea73f635fdd'

  useEffect(() => {
    if (!currentAccount.id) {
      return
    }

    fetchOverview(
      navigate,
      currentAccount.id,
    ).then(data => {
      setCompetitors(data.competitors.data.filter((competitor: any) => !competitor.type.includes('new', 'indirect')))
      setIndirectCompetitors(data.competitors.data.filter((competitor: any) => competitor.type.includes('indirect')))
      setNewCompetitors(data.competitors.data.filter((competitor: any) => competitor.type.includes('new')))

      setUpcomingInvestorEvents(data.investor_events.data)
      setInvestorNews(data.investor_news.data)
      setWebinarsConferences(data.webinars_conferences.data)
      setReports(REPORTS)
    })

    fetchCompanyUpdates(navigate).then((data) => {
      setCompanyUpdates(data.data)
    })
  }, [currentAccount?.id, currentUser?.id])

  const companyWithUpdates = competitors.map((company) => {
      return {
        company,
        updates: (companyUpdates || []).filter((companyUpdate) => companyUpdate.company_id === company.id)
      }
    })

  return (
    <PageContainer>
      <>
        <div className={"font-bold text-3xl px-10 pt-10 pb-6"}>Overview</div>
        <div className="grid grid-flow-rows grid-cols-5 px-10 gap-6 pb-10">
          <div className="flex flex-col col-span-3 gap-6">
            <div>
              <Header className="pb-3" size={'xtra-large'}>Companies</Header>
              <Table
                rows={companyWithUpdates}
                columnConfigs={columnConfigs}
                onRowClick={(row: any) => {
                  navigate(`/companies/${row.company.id}`)
                }}
              />
            </div>
            {isAdmin && reports.length > 0 && (
              <div>
                <Header className="pb-3" size={'xtra-large'}>Alerts</Header>
                <div className=" flex flex-col border border-slate-200 px-8 divide-y divide-slate-200">
                  {reports.map((alert: any) => {
                    return <Report alert={alert} />
                  })}
                </div>
              </div>
            )}
            {newCompetitors.length > 0 && (
              <div>
                <Header className="pb-3" size={'xtra-large'}>New entrants</Header>
                <Table rows={newCompetitors} columnConfigs={columnConfigs}/>
              </div>)
            }
            {indirectCompetitors.length > 0 && (
              <div>
                <Header className="pb-3" size={'xtra-large'}>Secondary competitors</Header>
                <Table rows={indirectCompetitors} columnConfigs={columnConfigs}/>
              </div>
            )}
          </div>
          <div className="flex flex-col col-span-2 gap-6">
            <BoxList
              title="Upcoming investor relations dates"
              titleSize='xtra-large'
              list={
                (upcomingInvestorEvents || []).map((event: any) => {
                  return { columnOne: `${event.company.name} - ${event.name}`, columnTwo: formatDate(event.date) || '' }
                })
              }
            />
            <div>
              <Header className="pb-3" size={'xtra-large'}>Upcoming webinars and conferences</Header>
              <Table rows={webinarsConferences} columnConfigs={upcomingWebinarConfig}/>
            </div>
            <BoxList
              title="Recent investor relations updates & analysis"
              titleSize='xtra-large'
              list={(investorNews || []).map((event: any) => {
                return {
                  columnOne: (
                    <div className="flex flex-row items-center">
                      <ExternalLink url={event.url}>
                        {event.name}
                      </ExternalLink>
                    </div>
                  ),
                  columnTwo: formatDate(event.date) || ''
                }
              })}
            />
          </div>
        </div>
      </>
    </PageContainer>
  )
}

export default Home