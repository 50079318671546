import { ReactElement } from "react"
import { formatUrl } from "../utils"

type ExternalLinkProps = {
  url?: string | null
  text?: string | null
  children?: ReactElement
  classNames?: string
}

const ExternalLink = ({ url, text, children, classNames } : ExternalLinkProps) => {
  const formattedUrl = formatUrl(url)

  if (!formattedUrl) {
    return <></>
  }

  return (
    <a href={formattedUrl} target="_blank" className={`text-ruby underline ${classNames}`}>
      {text}
      {children}
      {(!text && !children) && url}
      </a>
  )
}

export default ExternalLink