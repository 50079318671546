

import { Combobox, ComboboxInput } from "@headlessui/react"
import PageContainer from "../components/PageContainer"
import { Table } from "../components/Table"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { fetchCompanies, searchCompanies } from "../api"
import startCase from "lodash/startCase"
import { GlobeAltIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"
import ExternalLink from "../components/ExternalLink"
import LinkedInIcon from "../assets/linkedin-logo.svg?react"
import XIcon from "../assets/x-logo.svg?react"
import isNil from "lodash/isNil"

const COLUMN_CONFIGS = [
  {
    header: 'Company',
    classNames: 'text-lg',
    customRender: (row: any) => {
      return (
        <div className="flex flex-row items-center gap-4 py-2 text-base">
          <img src={row.logo_url} className="rounded-full h-16"/>
          {row.name}
        </div>
      )
    }
  },
  {
    header: 'Headquarters',
    key: 'headquarters',
    classNames: 'text-base',

  },
  {
    header: 'Stage',
    classNames: 'text-base',
    customRender: (row: any) => {
      return startCase(row.funding_stage)
    }
  },
  {
    header: 'Employee count',
    key: 'employee_count_range',
    classNames: 'text-base'
  },
  {
    header: 'Links',
    customRender: (row: any) => {
      return (
        <div className="flex flex-row gap-2">
          {row.website_url && <ExternalLink url={row.website_url}>
            <GlobeAltIcon className="h-6 stroke-ruby" />
          </ExternalLink>}
          {row.linkedin_url &&<ExternalLink url={row.linkedin_url}>
            <LinkedInIcon className="h-6 stroke-ruby" ></LinkedInIcon>
          </ExternalLink>}
          {row.x_url &&<ExternalLink url={row.x_url}>
            <XIcon className="h-6 stroke-ruby" ></XIcon>
          </ExternalLink>}
        </div>
      )
    }
  },
]

const Companies = () => {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  useEffect(() => {
    if (isNil(searchQuery) || searchQuery === '') {
      fetchCompanies(navigate).then(data => setCompanies(data))
      return
    }

    searchCompanies(navigate, searchQuery).then(data => setCompanies(data))
  }, [searchQuery])

  return (
    <PageContainer>
      <div className="p-10">
        <div className="flex flex-row justify-between pb-6">
          <div className={"font-bold text-3xl"}>Company Search</div>
          <div className="flex flex-row items-end justify-end">
          <div className="flex flex-row border rounded-full focus-within:outline focus-within:outline-2 focus-within:outline-purple/20 h-fit w-fit mb-3 py-2 px-1">
            <Combobox value={searchQuery} onChange={() => {console.log("onChange")}}  onClose={() => {console.log("onClose")}}>
              <MagnifyingGlassIcon className="w-4 h-4 self-center mx-2" />
              <ComboboxInput
                className="text-sm text-slate-900 focus:outline-none w-[250px]"
                aria-label="Company"
                displayValue={() => searchQuery || ''}
                onChange={(event) => setSearchQuery(event.target.value)}
                placeholder="Search companies"
              />
              <XMarkIcon className="w-4 h-4 self-center mx-2" onClick={() => setSearchQuery(null)}/>
            </Combobox>
          </div>
        </div>
        </div>
        <Table
          columnConfigs={COLUMN_CONFIGS}
          rows={companies}
          onRowClick={(row: any) => {
            navigate(`/companies/${row.id}`)
          }}
        />
      </div>
    </PageContainer>
  )
}

export default Companies