import { ReactElement } from "react"

type HeaderProps = {
  className?: string
  children?: ReactElement | string | number
  size?: 'small' | 'medium' | 'large' | 'xtra-large' | '2xtra-large'
  onClick?: () => void
}

const SIZE_MAPPING = {
  "small": "text-sm",
  "medium": "text-base",
  "large": "text-lg",
  "xtra-large": 'text-xl',
  "2xtra-large": 'text-2xl'
}

const COLOR_MAPPING = {
  "small": "text-feather",
  "medium": "text-feather",
  "large": "text-slate-700",
  "xtra-large": "text-slate-700",
  "2xtra-large": "text-slate-700"
}

const ONCLICK_COLOR_MAPPING = {
  "small": "text-feather hover:text-feather/80",
  "medium": "text-feather hover:text-feather/80",
  "large": "text-slate-700 hover:text-slate-700/80",
  "xtra-large": "text-slate-700 hover:text-slate-700/80",
  "2xtra-large": "text-slate-700 hover:text-slate-700/80"
}


const DEFAULT_SIZE = 'large'

const Header = ({ className, children, size, onClick }: HeaderProps) => {
  const sizeToUse = size || DEFAULT_SIZE
  const sizeClassName = SIZE_MAPPING[sizeToUse]
  const colorClassName = onClick ? ONCLICK_COLOR_MAPPING[sizeToUse] : COLOR_MAPPING[sizeToUse]
  const onClickStyles = onClick ? "cursor-pointer" : ""

  return (
    <div className={`font-bold w-fit ${onClickStyles} ${sizeClassName} ${className} ${colorClassName}`} onClick={onClick}>
      {children}
    </div>
  )
}

export default Header