import { combineReducers } from "@reduxjs/toolkit"
import { accountReducer } from "./reducers/accountReducer"
import { userReducer } from "./reducers/userReducer"
import { companyReducer } from "./reducers/companyReducer"

const rootReducer = combineReducers({
  user: userReducer,
  account: accountReducer,
  company: companyReducer
})

export default rootReducer