import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { SignIn } from './pages/SignIn';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Company from './pages/Company';
import Financials from './pages/Company/Financials';
import Documents from './pages/Company/Documents';
import CompanyNews from './pages/Company/CompanyNews';
import Search from './pages/Search'
import Success from './pages/Success';
import rootReducer from './rootReducer';
import News from './pages/News';
import Companies from './pages/Companies';
import { MathJaxContext } from 'better-react-mathjax'
import Chat from './pages/Chat';
import Sentiment from './pages/Company/Sentiment';
import posthog from 'posthog-js'
import Reports from './pages/Reports';
import Report from './pages/Report';

const store = configureStore({
  reducer: rootReducer
})

posthog.init(import.meta.env.VITE_POSTHOG_KEY,
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<PrivateRoute><Home></Home></PrivateRoute>} />
        <Route path="/success" element={<Success />} />
        <Route path="/settings" element={<PrivateRoute><Settings></Settings></PrivateRoute>} />
        <Route path="/search" element={<PrivateRoute><Search></Search></PrivateRoute>} />
        <Route path="/companies" element={<PrivateRoute><Companies></Companies></PrivateRoute>} />
        <Route path="/companies/:id" element={<PrivateRoute><Company></Company></PrivateRoute>} />
        <Route path="/companies/:id/financials" element={<PrivateRoute><Financials></Financials></PrivateRoute>} />
        <Route path="/companies/:id/documents" element={<PrivateRoute><Documents></Documents></PrivateRoute>} />
        <Route path="/companies/:id/news" element={<PrivateRoute><CompanyNews></CompanyNews></PrivateRoute>} />
        <Route path="/news" element={<PrivateRoute><News></News></PrivateRoute>} />
        <Route path="/chat" element={<PrivateRoute><Chat></Chat></PrivateRoute>} />
        <Route path="/companies/:id/sentiment" element={<PrivateRoute><Sentiment></Sentiment></PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute><Reports></Reports></PrivateRoute>} />
        <Route path="/reports/:id" element={<PrivateRoute><Report></Report></PrivateRoute>} />
        {/* <Route path="/create-report" element={<PrivateRoute><CreateReport></CreateReport></PrivateRoute>} /> */}
        {/* <Route path='/reports/:id' element={<PrivateRoute><ReportDetails></ReportDetails></PrivateRoute>} /> */}
        {/* <Route
          path='/reports/:id/news_source/:news_source_id'
          element={<PrivateRoute><ReportNewsSourceDetails></ReportNewsSourceDetails></PrivateRoute>}
        /> */}
        <Route path="/signin" element={<SignIn />} />
      </>
    )
  )

function App() {
  return (
    <>
      <Provider store={store}>
        <MathJaxContext>
          <RouterProvider router={router}/>
        </MathJaxContext>
      </Provider>
    </>
  )
}

export default App
