import axios from "axios"
import Cookies from 'js-cookie'
import isNil from "lodash/isNil"

export const RIVETER_COOKIE = 'X-Riveter-Cookie'

export const downloadResponseFile = (response: any, fileName: string) => {
  const file = new Blob([response.data], { type: 'application/pdf' })
  const href = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export const downloadFileFromURL = (url: string, fileName: string) => {
  console.log(url, fileName)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}

export const fetchCompetitors = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/competitors`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchReports = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/reports`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const createReport = async (navigate: any, body: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.post(`${import.meta.env.VITE_API_URL}/v1/reports`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
} 

export const fetchReport = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/reports/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchCompany = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/companies/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchNewsSource = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/news_source/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchDocuments = async(navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/companies/${id}/documents`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const downloadDocument = async(navigate: any, companyId: string, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/companies/${companyId}/documents/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  console.log(response?.data)

  downloadFileFromURL(response?.data?.data?.url, `${id}.pdf`)
}

export const fetchNewsArticles = async(navigate: any, id?: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  const path = id ? `/v1/companies/${id}/news_articles` :  "/v1/news_articles"

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}${path}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const searchLLM = async (navigate: any, query: string, company: string, fiscalYear: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/llm/search`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params: {
        query,
        company,
        fiscal_year: fiscalYear
      }
    })
  } catch (error: any) {
    if (error.response?.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data
}

export const login = async () => {
  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/login`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  } catch (error: any) {
    throw error
  }

  return response?.data
}

export const fetchCookie = async (code: string) => {
  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/login/cookie?code=${code}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    Cookies.set(RIVETER_COOKIE, response.data.data.token, { expires: 7 })
  } catch (error: any) {
    throw error
  }

  return !!response?.data
}

export const logout = async () => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    throw error
  }

  return response?.data
}

type FetchCompanyEventsParams = {
  upcoming?: boolean
  per?: number
  page?: number
  event_type?: string
}

export const fetchCompanyEvents = async (
  navigate: any,
  { upcoming, per, page, event_type}: FetchCompanyEventsParams
) => {
  const token = Cookies.get(RIVETER_COOKIE)

  const params = {
    upcoming,
    per,
    page,
    event_type
  }

  const queryString = Object.entries(params)
                            .filter((keyAndValue) => !!keyAndValue[1])
                            .map((keyAndValue) => `${keyAndValue[0]}=${keyAndValue[1]}`)
                            .join("&")

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/company_events?${queryString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)
      console.log('navigating...')
      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchOverview = async (navigate: any, account_id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/accounts/${account_id}/overview`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchMe = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/me`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchCompanyUpdates = async (navigate: any, companyId?: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  const companyIdString = isNil(companyId) ? '' : `?company_id=${companyId}`

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/company_updates${companyIdString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data
}

export const fetchCompanies = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/companies`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const searchCompanies = async (navigate: any, query: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search/companies?query=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const createChat = async (navigate: any) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.post(`${import.meta.env.VITE_API_URL}/v1/chat`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
} 

export const fetchChat = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/chat/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
} 

export const fetchChatMessages = async (navigate: any, id: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/chat/${id}/messages`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
} 

export const createChatMessage = async (navigate: any, chat_id: string, message: string) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.post(`${import.meta.env.VITE_API_URL}/v1/chat/${chat_id}/messages`, 
      {
        "content": message
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    )
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}

export const fetchSentiment = async (navigate: any, companyId: string, id: string, sentiment?: string | null) => {
  const token = Cookies.get(RIVETER_COOKIE)

  let response
  try {
    response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/companies/${companyId}/sentiment/${id}${sentiment ? `?sentiment=${sentiment}` : ''}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
  } catch (error: any) {
    if (error.response.status === 401) {
      Cookies.remove(RIVETER_COOKIE)

      navigate("/signin")
    } else {
      throw error
    }
  }

  return response?.data?.data
}
