import { Navigate } from 'react-router-dom';
import { isLoggedIn } from '../auth/utils'
import { useEffect } from 'react';
import { login } from '../api';
import { ThreeDots } from 'react-loader-spinner';

export const SignIn = () => {

  if (isLoggedIn()) {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    if (isLoggedIn()) {
      return
    }

    login().then(data => {
      window.location.href = data.data.redirect_url
    })
  }, [])

  return (
    <div className="flex flex-row align-center justify-center w-screen h-screen bg-faintOrange">
      <div className="flex flex-col align-center justify-center">
        <div className="flex flex-row justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="#92111E"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </div>
  )
}
