import React, { useState } from "react";
import PageContainer from "../components/PageContainer";
import Button from "../components/Button";
import { searchLLM } from "../api";
import { useNavigate } from "react-router-dom";

const LLMChat = () => {
  const [query, setQuery] = useState("")
  const [answer, setAnswer] = useState("")
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await searchLLM(navigate, query, "intuit", "fy24")
    setAnswer(response?.data?.answer)
    setQuery("");
  };

  return (
    <PageContainer>
      <div className="flex justify-center">
        <div className="max-w-xl w-full">
          <h1 className="text-2xl font-bold mt-20 mb-6 text-feather">Ask a question</h1>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <label htmlFor="query" className="text-feather text-sm">Your question</label>
              <input
                id="query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="border border-slate-300 rounded-lg px-3 py-2"
                placeholder="Type your question here..."
              />
            </div>
            <Button className="self-end w-fit" type="submit">Submit</Button>
          </form>
          {answer && <div className="mt-4 p-4 border-slate-300 bg-white rounded-lg border">{answer}</div>}
        </div>
      </div>
    </PageContainer>
  );
};

export default LLMChat;
